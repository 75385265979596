import React from 'react';
// import dental from './work/dental.jpg'
// import veneers from './work/veeners.jpg'
import one from './work/one.png'
import two from './work/two.png'
import three from './work/three.png'
import four from './work/four.png'
import five from './work/five.png'
import six from './work/six.png'
import seven from './work/seven.png'

export const Services = () => {
  return (
    <div id="services" classname="text-center">
    <div classname="container row">
      <div id="myCarousel" className="carousel slide col-md-8 col-md-offset-2" data-ride="carousel" >
        <ol className="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to={0} className="active" />
          <li data-target="#myCarousel" data-slide-to={1} />
          <li data-target="#myCarousel" data-slide-to={2} />
          <li data-target="#myCarousel" data-slide-to={3} />
          <li data-target="#myCarousel" data-slide-to={4} />
          <li data-target="#myCarousel" data-slide-to={5} />
          <li data-target="#myCarousel" data-slide-to={6} />
        </ol>
        <div className="carousel-inner">
          <div className="item active overlays w-100">
            <img src={seven} alt="Medpoint" className="img-responsive"/>
            
          </div>
          <div className="item overlays">
            <img src={four} alt="Medpoint" className="img-responsive"/>
            {/* <div className="carousel-caption">
              <h2 className='text-muted'>Teeth Implants</h2>
              <p>LA is always so much fun!</p>
            </div> */}
          </div>
          <div className="item overlays">
            <img src={one} alt="Medpoint" className="img-responsive"/>
          </div>
          <div className="item overlays">
            <img src={two} alt="Medpoint" className="img-responsive"/>
          </div>
          <div className="item overlays">
            <img src={three} alt="Medpoint" className="img-responsive"/>
          </div>
          <div className="item overlays">
            <img src={five} alt="Medpoint" className="img-responsive"/>
          </div>
          <div className="item overlays">
            <img src={six} alt="Medpoint" className="img-responsive"/>
          </div>
          
        </div>
        {/* Left and right controls */}
        {/* <a className="left carousel-control" href="#myCarousel" data-slide="prev">
          <span className="glyphicon glyphicon-chevron-left" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="right carousel-control" href="#myCarousel" data-slide="next">
          <span className="glyphicon glyphicon-chevron-right" />
          <span className="sr-only">Next</span>
        </a> */}
      </div>
    </div>
  </div>
  )
}
