// import col from "./react-boostrap/col";
import headerFront from './header-front.png'
import React from 'react';
export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro img-responsive'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text' style={{flexDirection:'column'}}>
                {/* <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1> */}
                <div className="d-block mx-auto h-img">
                  <img alt='medpoint logo' src={headerFront} fluid responsive className="img-fluid" />
                </div>
                {/* <p><i>{props.data ? props.data.paragraph : 'Loading'}</i></p> */}
                <br/> <br/> <br/>
                <a href='https://wa.me/263714924797' className='btn btn-custom btn-lg page-scroll' >
                  Book Now
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
