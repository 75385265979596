import React,{ useState } from 'react'
import emailjs from 'emailjs-com'
// import Swal from 'react-bootstrap-sweetalert';
import Swal from "sweetalert";

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_np20coj', 'template_tc380ll', e.target, 'WcAcUp61vvgsXlnOs' 
      )
      .then(
        result => {
          console.log(result.text)
          clearState()
          Swal("Message Sent Successfully!", "", "success");
          
        },
        error => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container row'>
          <div className='col-md-6 col-lg-6 col-md-offset-1 my-10'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>

                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success' className='center mx-auto'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-4 col-lg-4 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i>Corner 2<sup>nd</sup> Street, 95 Baines Avenue, Harare
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> +263 714 924 797
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> reception@medpointdental.co.zw
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-whatsapp'></i> +263 714 924 797
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : ''}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : ''}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : ''}>
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
           <row>
             <div className="col-md-6 col-sm-6"> &copy; {new Date().getFullYear()} Medpoint Dental Clinic. </div>
             <div className="col-md-6 col-sm-6">
             <a href='https://www.codewand.co.zw' rel='nofollow'>
             Design by{' '}Codewand Technologies
            </a>
             </div>
           </row>
          </p>
        </div>
      </div>
    </div>
  )
}
