import React from 'react';
import { Services } from './services';

export const Testimonials = (props) => {
  return (
    <div id='servces'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>SERVICES</h2>
        </div>
        <div className='row'>
          {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src={d.img} alt='Medpoint Dental' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      <div className='testimonial-meta'> {d.name} </div>
                      <p>{d.text}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'} */}
            <Services/>
        </div>
      </div>
    </div>
  )
}
