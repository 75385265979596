import React from 'react';
import medpoint_team from "./medpoint-team.jpg"
import medpoint_work from "./medpoint-work.jpg"
import medOne from './medpoint-one.png';
import medTwo from './medpoint-two.png';
export const Features = () => {
  return (
    <div id='features' >
      <div className='container w-75'>
        <div className='col-md-10 col-md-offset-1 section-title mt-100 text-center'>
          <h2>Medpoint Dental Clinic</h2>
        </div>
        <div className='row justify-content-around'>

          <div className="col-lg-8 col-md-8 col-sm-12 mt-5 ">
            <div className="text-center" fluid responsive>
              <img src={medOne} alt='Medpoint Dental' />
            </div>
            <p className='mt-20 my-10 '>
              Exceptional quality dental treatment is our philosophy and that starts with an exceptional team. Medpoint Dental Surgery is a leading dental care
              center experienced in providing advanced dental care for beautiful and lasting smiles.
              We are conveniently located at Corner Baines Avenue in Harare. Medpoint Dental skilled team aims for excellence in every aspect of your experience
              and never compromise.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mt-5 fImg d-block mx-auto text-center">
            <img src={medpoint_work} alt="Medpoint Dental" className='img-fluid' />
          </div>
        </div>


        <div className='row'>
          <div className="col-lg-8 col-md-8 col-sm-12 mt-25 ">
            <div className="text-center" fluid responsive>
              <img src={medTwo} alt='Medpoint Dental' />
            </div>
            <p className="'mt-20 my-10 mt-5">
              Medpoint dental surgery specialises in different dental procedures that include dental impants, orthodontics, endodontics, dental veeners, bridges,
              invisalign, root canal, cosmetic dentistry and smile makeovers. Returning satisfied clients have been a major boost to medpoint success. <br></br>
              We value our clients heath and will always perform clinical assessment before dental procedure and aftercare is a norm at Medpoint dental surgery.
            </p>

          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mt-5 fImg d-block mx-auto text-center">
            <img src={medpoint_team} alt="Medpoint Dental" className='img-fluid'/>
          </div>
        </div>

      </div>
    </div>
  )
}
