import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
// import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { ReactComponent as CompanyIcon } from './medlogo.png';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import "./App.css";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1250,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
    
  return (
    <div className="w-100">
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Gallery data={landingPageData.Gallery}/>
      <Testimonials data={landingPageData.Testimonials} />
      {/* <Services data={landingPageData.Services} /> */}
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
      <WhatsAppWidget CompanyIcon={CompanyIcon} phoneNumber="263714924797" />
    </div>
  );
};

export default App;
